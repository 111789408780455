import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  btn: {
    cursor: 'pointer',
    display: 'block',
    border: `1px solid ${theme.palette.text.white}`,
    color: theme.palette.text.white,
    padding: '15px 42px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.secondary.main}`,
    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
    },
  },
});

function Button(props) {
  const { classes, link, text } = props;
  return (
    <Link to={link} className={classes.btn}>
      <Typography component="span" variant="caption">
        {text}
      </Typography>
    </Link>
  );
}

Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Button);
