import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import RoomIcon from '@material-ui/icons/Room';
import ListIcon from '@material-ui/icons/List';

const styles = theme => ({
  btn: {
    cursor: 'pointer',
    display: 'block',
    border: `1px solid ${theme.palette.text.white}`,
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
    padding: '15px 42px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.secondary.main}`,
    transition: 'box-shadow 0.2s ease-in-out',
    outline: 0,
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function MapToggleBtn(props) {
  const { classes, text, ClickFunction, icon } = props;
  return (
    <button onClick={ClickFunction} className={classes.btn}>
      <Box component="span" display="flex" alignItems="center">
        <Box
          component={Typography}
          variant="button"
          className={classes.uppercase}
        >
          {text}
        </Box>
        <Box ml={1}>
          {icon == 'map' && <RoomIcon />}
          {icon == 'list' && <ListIcon />}
        </Box>
      </Box>
    </button>
  );
}

MapToggleBtn.propTypes = {
  ClickFunction: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapToggleBtn);
