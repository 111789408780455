import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  btn: {
    cursor: 'pointer',
    display: 'inline-block',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.secondary.main}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function ButtonOutlineLightExternal(props) {
  const { classes, link, text } = props;
  return (
    <a href={link} target="_blank" rel="noreferrer" className={classes.btn}>
      <Typography
        component="span"
        variant="button"
        className={classes.uppercase}
      >
        {text}
      </Typography>
    </a>
  );
}

ButtonOutlineLightExternal.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonOutlineLightExternal);
