import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  text: {
    display: 'none',
  },
  icon: {
    color: theme.palette.primary.main,
    height: '30px',
    width: 'auto',
    padding: '0 5px',
  },
  btn: {
    lineHeight: '0 !important',
    outline: 0,
  },
  wrapper: {
    borderTop: `1px solid ${theme.palette.background.border}`,
  },
});

function Share(props) {
  const { url, title, classes } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.wrapper}
      pt={4}
      mt={6}
    >
      <Box mr={4}>
        <Typography component="p" variant="button">
          SHARE ON
        </Typography>
      </Box>
      <FacebookShareButton
        url={process.env.BASE_URL + url}
        className={classes.btn}
      >
        <FacebookIcon className={classes.icon} />
        <span className={classes.text}>Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton
        url={process.env.BASE_URL + url}
        className={classes.btn}
        title={title}
        via="@hayfieldhomes"
      >
        <span className="icon">
          <TwitterIcon className={classes.icon} />
        </span>
        <span className={classes.text}>Twitter</span>
      </TwitterShareButton>
      <LinkedinShareButton
        url={process.env.BASE_URL + url}
        className={classes.btn}
        title={title}
      >
        <span className="icon">
          <LinkedInIcon className={classes.icon} />
        </span>
        <span className={classes.text}>LinkedIn</span>
      </LinkedinShareButton>
    </Box>
  );
}

Share.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Share);
