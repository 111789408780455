import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  link: {
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  linkItem: {
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
  },
  line: {
    display: 'block',
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
});

function ButtonUnderline(props) {
  const { classes, link, text } = props;
  return (
    <Link to={link} className={classes.link}>
      <Typography
        className={classes.linkItem}
        component="span"
        variant="subtitle2"
        color="textPrimary"
      >
        {text ? text : 'View'}
      </Typography>
      <Box component="span" className={classes.line} />
    </Link>
  );
}

ButtonUnderline.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonUnderline);
