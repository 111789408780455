import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  btn: {
    cursor: 'pointer',
    display: 'inline-block',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function ExternalButtonBlockDark(props) {
  const { classes, link, text } = props;
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer nofollow"
      className={classes.btn}
    >
      <Typography
        component="span"
        variant="button"
        className={classes.uppercase}
      >
        {text}
      </Typography>
    </a>
  );
}

ExternalButtonBlockDark.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExternalButtonBlockDark);
